import { skipToken, useQuery } from '@tanstack/react-query';

import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import useSessionTimeoutStore from '../../context/session-timeout-context.ts';
import type { HTTPResponse } from '../../types/api.type';
import type { QuotesResponse } from '../../types/quote.type.ts';
import { QuotingStatus } from '../../types/quote.type.ts';
import api from '../api';

function useQuotesQuery(gid: string | null, enabled?: boolean, throwOnError?: boolean) {
  const updateLastUserInteractionTime = useSessionTimeoutStore(s => s.updateLastUserInteractionTime);

  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.quotes, gid],
    refetchInterval: (query) => {
      return query.state.data?.quotes_request_status === QuotingStatus.Pending ? 3000 : false;
    },
    throwOnError,
    queryFn: gid && enabled
      ? async () =>
        await api.get<HTTPResponse<QuotesResponse>>(API_PATH.quotes.replace(':gid', gid)).then((res) => {
          if (res.session.refreshed_at) {
            updateLastUserInteractionTime(res.session.refreshed_at * 1000);
          }
          return res.data;
        })
      : skipToken,
  });
}

export default useQuotesQuery;
