import { useMutation, useQueryClient } from '@tanstack/react-query';

import API_PATH from '../../constants/api.ts';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys.ts';
import useSessionTimeoutStore from '../../context/session-timeout-context.ts';
import type { ProfileFormState } from '../../pages/account/ProfileForm/ProfileForm.type.ts';
import type { HTTPResponse } from '../../types/api.type.ts';
import type { AccountResponse } from '../../types/account.type.ts';
import api from '../api.ts';

function useMutateAccount(gid: string) {
  const updateLastUserInteractionTime = useSessionTimeoutStore(s => s.updateLastUserInteractionTime);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ProfileFormState) =>
      await api
        .patch<HTTPResponse<AccountResponse>>(API_PATH.account.replace(':gid', gid), {
          body: { data: { account: data } },
        })
        .then(async (res) => {
          if (res.session.refreshed_at) {
            updateLastUserInteractionTime(res.session.refreshed_at * 1000);
          }
          await queryClient.setQueryData([QUERY_CACHE_KEYS.account, res.data.account.gid], res.data);

          return res.data;
        }),
  });
}

export default useMutateAccount;
