import { useMutation, useQueryClient } from '@tanstack/react-query';

import API_PATH from '../../constants/api.ts';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys.ts';
import useSessionTimeoutStore from '../../context/session-timeout-context.ts';
import type { HTTPResponse } from '../../types/api.type.ts';
import type { VerifyAccountResponse } from '../../types/account.type.ts';
import api from '../api.ts';

function useVerify(gid: string) {
  const queryClient = useQueryClient();
  const updateLastUserInteractionTime = useSessionTimeoutStore(s => s.updateLastUserInteractionTime);

  return useMutation({
    mutationFn: async (data: {
      last_name: string;
      date_of_birth: string;
      zip: string;
    },
    ) =>
      await api
        .patch<
        HTTPResponse<VerifyAccountResponse>
      >(API_PATH.verifyAccount.replace(':gid', gid), { body: { data: { account: data } } })
        .then((res) => {
          queryClient.setQueryData([QUERY_CACHE_KEYS.account, gid], res.data);
          if (res.session.refreshed_at) {
            updateLastUserInteractionTime(res.session.refreshed_at * 1000);
          }
          return res.data;
        }),
  });
}

export default useVerify;
