import type { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useSettingsQuery from '../../api/settings/use-settings-query';
import carIcon from '../../assets/svg/car.svg';
// import leafIcon from '../../assets/svg/leaf.svg';
import shieldIcon from '../../assets/svg/checkbox-shield.svg';
import homeIcon from '../../assets/svg/home.svg';
import PageContainer from '../../components/PageContainer/PageContainer';
import ROUTES from '../../constants/routes.ts';
import useSegment from '../../hooks/use-segment.ts';
import useTrackPage from '../../hooks/use-track-page.ts';
import { pageHeadingCSS } from '../../styles/layout.style';
import { AnalyticAddNewPolicyVariant, AnalyticPageKey } from '../../types/analytics.type.ts';
import type { DefaultRouteParams } from '../../types/route-params.type';
import useAccountQuery from '../../api/account/use-account-query.ts';
import useDpNavigate from '../../hooks/use-dp-navigate.ts';
import { DpCta } from '../../types/digital-profile.type.ts';
import { policiesWrapperCSS } from './index.style';
import PolicyCard from './PolicyCard/PolicyCard';
import FooterCopy from './FooterCopy/FooterCopy.tsx';

const PoliciesLayout: FC = () => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data: settingsData } = useSettingsQuery();
  const { data: accountData } = useAccountQuery(gid);
  const navigateToDp = useDpNavigate();

  const { track } = useSegment();

  const navigate = useNavigate();

  useTrackPage(AnalyticPageKey.Policies);

  const onActionClick = async (variant: AnalyticAddNewPolicyVariant) => {
    track('Add New Policy Clicked', {
      policy_variant: variant,
    });

    switch (variant) {
      case 'home':
        if (accountData?.account.profile_completed_at) {
          navigate(ROUTES.scheduleCall.replace(':gid', gid));
          return;
        }
        navigateToDp(DpCta.Quotes, AnalyticPageKey.Policies);
        break;
      case 'auto':
        if (settingsData?.matic_auto_flow_url) {
          window.location.href = settingsData?.matic_auto_flow_url;
        }
        break;
      case 'other':
        navigate(ROUTES.scheduleCall.replace(':gid', gid));
        break;
    }
  };

  return (
    <PageContainer pageKey={AnalyticPageKey.Policies} footerCopy={<FooterCopy />}>
      <h1 css={pageHeadingCSS}>
        My Policies
      </h1>

      <div css={policiesWrapperCSS}>
        <PolicyCard
          icon={{ src: homeIcon }}
          title="Add a home policy"
          onActionClick={() => onActionClick(AnalyticAddNewPolicyVariant.Home)}
        />
        <PolicyCard
          icon={{ src: carIcon }}
          title="Add an auto policy"
          onActionClick={() => onActionClick(AnalyticAddNewPolicyVariant.Auto)}
        />
        {/* <PolicyCard icon={{ src: leafIcon }} title="Add a life policy" onActionClick={onActionClick} /> */}
        <PolicyCard
          icon={{ src: shieldIcon }}
          title="Add another policy"
          onActionClick={() => onActionClick(AnalyticAddNewPolicyVariant.Other)}
          buttonText="Schedule a Call"
        />
      </div>

    </PageContainer>
  );
};

export default PoliciesLayout;
