import type { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import PageInfoContainer from '../../components/PageInfoContainer/PageInfoContainer.tsx';
import CatImage from '../../assets/svg/error-cat.svg?react';
import Button from '../../shared/components/Button/Button.tsx';
import ROUTES from '../../constants/routes.ts';
import useSettingsQuery from '../../api/settings/use-settings-query.ts';
import useSegment from '../../hooks/use-segment.ts';
import { AnalyticLocation, AnalyticPageKey } from '../../types/analytics.type.ts';
import { buttonCSS, descriptionCSS, imageCSS } from './index.style.ts';

const ErrorLayout: FC = () => {
  const [searchParams] = useSearchParams();
  const accountGID = searchParams.get('gid');
  const { data: settingsData } = useSettingsQuery(false);
  const { track } = useSegment();

  const onPhoneClicked = (): void => {
    track('Phone Conversation Initiated', {
      page: AnalyticPageKey.Error,
      location: AnalyticLocation.Footer,
    });
  };

  const onActionClick = () => {
    document.location.href = accountGID ? ROUTES.insights.replace(':gid', accountGID) : ROUTES.signIn;
  };

  return (
    <PageInfoContainer
      heading="Something went wrong"
      footer={{
        phoneNumber: settingsData?.phone_number || '',
        onSocialLinkClick: onPhoneClicked,
      }}
    >
      <CatImage css={imageCSS} />
      <p css={descriptionCSS}>
        This page is missing or our servers are having temporary issues. We’re working on it.
      </p>
      <Button customCSS={buttonCSS} size="large" onClick={onActionClick}>
        Back to homepage
      </Button>
    </PageInfoContainer>
  );
};

export default ErrorLayout;
