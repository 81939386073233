export const HOW_WE_CALCULATE_RISK_SCORE = 'Your risk score provides a view into how carriers determine the'
  + 'risk to insure your home. This is intended to help you understand how your level of risk may impact your '
  + 'insurance premiums and carrier options. Your risk score can range from low, to med-low, medium, med-high, or '
  + 'high. The factors used to calculate this risk score include location, claims, credit history, home age, roof '
  + 'condition, pets, security hazards and heating source. (View your factors here) Matic uses information available '
  + 'to us to estimate a level of risk for each factor, and generate an overall risk score. Missing or incorrect '
  + 'information can reduce the usefulness of this illustration. If and when additional information is provided your '
  + 'risk score may change. The level of risk identified for each factor is Matic’s interpretation based on experience '
  + 'with our panel of 40+ carriers. It does not describe the actual rating or underwriting decisions of the carriers '
  + 'themselves. Actual factors used by carriers for rating and underwriting vary. Your risk score and associated '
  + 'factors are provided for illustrative purposes only and do not predict actual outcomes. As a homeowner, there is '
  + 'always a risk you will need to file a claim. State laws may change and credit may be used as permitted '
  + 'by applicable law.';

export const HOW_WE_CALCULATE_PROTECTION_SCORE = 'Your protection score shows how protected you are based on your '
  + 'level of coverage and steps you’ve taken to protect your home from potential damage. Your protection score can '
  + 'range from poor, to moderate, good, very good, or excellent. The factors used to calculate your protection score '
  + 'take into account your current coverage and the steps you’ve taken to help protect your home from damage.'
  + '(View your factors here) Matic uses information available to us to estimate a level of protection for each '
  + 'factor. Missing or incorrect information can reduce the usefulness of this illustration. If and when additional '
  + 'information is provided, your protection score may change.  The level of protection identified for each factor '
  + 'is based on Matic’s typical recommendations for home insurance policy coverages, as well as safety precautions '
  + 'you’ve taken to protect your home from fire, theft, and water damage. Your protection score and associated '
  + 'factors are provided for illustrative purposes only and do not predict whether you have adequate coverage for '
  + 'your needs. As a homeowner you are responsible for evaluating your own coverage needs to ensure adequate '
  + 'coverage in the event of a loss.';

export const SCORE_DETAILS = {
  risk: 'This score helps you understand how carriers view how risky you are to insure and what you can do about it.',
  protection: 'How protected are you? Fill out a short survey to understand how covered you are during a disaster.',
};

export function revertRiskScoreValue(value?: number | null, maxValue?: number | null): number | null {
  if (typeof value !== 'number' || typeof maxValue !== 'number') {
    return null;
  }

  return maxValue - value;
}
