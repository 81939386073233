import { css } from '@emotion/react';

export const logoCSS = css`
  height: 24px;
  display: block;
  margin: 20px auto 0;
`;

export const containerInnerCSS = css`
  padding-top: 100px;
  text-align: center;
`;

export const headingCSS = css`
  margin: 0;
  font-size: 40px;
  line-height: 52px;
  font-weight: 700;
`;

export const subHeadingCSS = css`
  margin-top: 4px;
`;

export const footerCSS = css`
  margin-top: 100px;
`;
