import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../shared/components/Button/Button';
import documentIcon from '../../assets/svg/doc-with-gear.svg';
import errorIcon from '../../assets/svg/forbidden-sheet.svg';
import noQuotesIcon from '../../assets/svg/sheet-with-sad-leaf.svg';
import { Card } from '../../shared/components/Card/Card';
import SIRV_IMAGES from '../../constants/sirv-images';
import useSuggestedQuoteQuery from '../../api/quote/use-suggested-quote-query.ts';
import type { DefaultRouteParams } from '../../types/route-params.type.ts';
import useAccountQuery from '../../api/account/use-account-query.ts';
import type { Quote } from '../../types/quote.type.ts';
import { QuotingStatus } from '../../types/quote.type.ts';
import useDpNavigate from '../../hooks/use-dp-navigate.ts';
import { rightColumnButtonCustomCSS } from '../../styles/layout.style.ts';
import { DpCta } from '../../types/digital-profile.type.ts';
import useQuotesQuery from '../../api/quotes/use-quotes-query.ts';
import useRecentPolicyQuery from '../../api/recent-policy/use-recent-policy-query.ts';
import useSegment from '../../hooks/use-segment.ts';
import { AnalyticLocation, AnalyticSuggestedPolicyOfferResult } from '../../types/analytics.type.ts';
import { cardCSS } from './SuggestedPolicy.style';
import type { SuggestedPolicyProps } from './SuggestedPolicy.type';
import { SuggestedQuoteState } from './SuggestedPolicy.type';
import QuoteDetails from './QuoteDetails/QuoteDetails.tsx';
import { getQuoteState } from './SuggestedPolicy.util.ts';
import SuggestedPolicySkeleton from './SuggestedPolicySkeleton/SuggestedPolicySkeleton.tsx';
import SuggestedPolicyMessage from './SuggestedPolicyMessage/SuggestedPolicyMessage.tsx';

const SuggestedPolicy: FC<SuggestedPolicyProps> = ({ customCSS, page, onShowMoreQuotesClick }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data: accountData } = useAccountQuery(gid);
  const { data: quotesData, isError: isQuotesError } = useQuotesQuery(gid, !!accountData?.account.profile_completed_at);
  const isQuotingFinished = quotesData?.quotes_request_status === QuotingStatus.Finished;
  const { data: suggestedQuoteData, isError: isSuggestedQuoteError } = useSuggestedQuoteQuery(gid, isQuotingFinished);
  const navigateToDp = useDpNavigate();
  const { data: recentPolicyData } = useRecentPolicyQuery(gid);

  const { track } = useSegment();

  const personHasBundleInterest = Boolean(accountData?.person_has_core_bundle_interest);

  const quoteState = getQuoteState(
    quotesData?.quotes_request_status,
    accountData,
    quotesData,
    suggestedQuoteData,
    isQuotesError || isSuggestedQuoteError,
  );

  const handleUnlockQuotes = () => {
    track('Unlock Portal Data Requested', {
      page,
      location: AnalyticLocation.SuggestedPolicy,
    });
    navigateToDp(DpCta.Quotes, page);
  };

  const trackQuoteMessageRender = (type: 'no_quotes' | 'error') => {
    track('Suggested Policy Viewed', {
      page,
      offer_result: type === 'error'
        ? AnalyticSuggestedPolicyOfferResult.Error
        : AnalyticSuggestedPolicyOfferResult.NoQuotes,
      quotes: [],
      savings: null,
    });
  };

  const getCardContent = () => {
    switch (quoteState) {
      case SuggestedQuoteState.Ready: {
        const quote = suggestedQuoteData?.quote as unknown as Quote;
        const autoQuote = personHasBundleInterest ? suggestedQuoteData?.auto_quote?.quote : undefined;
        return (
          <QuoteDetails
            page={page}
            quote={quote}
            autoQuote={autoQuote}
            recentPolicy={recentPolicyData?.person_policy}
          />
        );
      }
      case SuggestedQuoteState.QuotingInProgress:
        return (
          <SuggestedPolicyMessage
            title="Gathering Quotes"
            message="We are currently fetching quotes from our list of 40+ carriers.
              We will let you know as soon as the quotes are ready."
            image={{ src: SIRV_IMAGES.documentAnimLoader, alt: 'Paper with plant', maxHeight: 180 }}
          />
        );
      case SuggestedQuoteState.NoDataForQuoting:
        return (
          <SuggestedPolicyMessage
            message="We’d love to give you a recommendation,
              but we need to get to know you before we suggest a policy."
            image={{ src: documentIcon, alt: 'Paper with gears', maxHeight: 160 }}
            buttonLabel="Unlock Your Quotes"
            onActionClick={handleUnlockQuotes}
          />
        );
      case SuggestedQuoteState.NoQuotes:
        return (
          <SuggestedPolicyMessage
            title="No Home Quotes Available"
            message="It appears that we have no quotes available for you at this time.
              One or more of your risk factors may be influencing our carriers decisions.
              Check your carrier options to see your declination reasons."
            image={{ src: noQuotesIcon, alt: 'Paper with sad plant', maxHeight: 170 }}
            buttonLabel="View Carrier Options"
            onRender={() => trackQuoteMessageRender('no_quotes')}
            onActionClick={onShowMoreQuotesClick}
          />
        );
      case SuggestedQuoteState.Error:
        return (
          <SuggestedPolicyMessage
            title="Quote Error"
            message="There was an error gathering your quotes.
              Something happened when communicating with our carriers.
              Try again in 15 minutes and we should have something for you."
            image={{ src: errorIcon, alt: 'Paper with crossed sign', maxHeight: 160 }}
            onRender={() => trackQuoteMessageRender('error')}
          />
        );
      case SuggestedQuoteState.Unknown:
      default:
        return <SuggestedPolicySkeleton />;
    }
  };

  return (
    <>
      <Card customCSS={[cardCSS, customCSS]}>
        {getCardContent()}
      </Card>
      {[SuggestedQuoteState.QuotingInProgress, SuggestedQuoteState.Ready].includes(quoteState) && (
        <Button fullWidth variant="secondary" css={rightColumnButtonCustomCSS} onClick={onShowMoreQuotesClick}>
          See Other Quotes
        </Button>
      )}
    </>
  );
};

export default SuggestedPolicy;
