export const CHART_DATA_SET = {
  status: 'ready',
  history: {
    2022: {
      replacement_cost: '300000',
      replacement_cost_min: '270000',
      replacement_cost_max: '330000',
    },
    2023: {
      replacement_cost: '315000',
      replacement_cost_min: '283500',
      replacement_cost_max: '346500',
    },
    2024: {
      replacement_cost: '330000',
      replacement_cost_min: '297000',
      replacement_cost_max: '363000',
    },
    2025: {
      replacement_cost: '350000',
      replacement_cost_min: '315000',
      replacement_cost_max: '385000',
    },
  },
};

export const EST_REPLACEMENT_TOOLTIP = 'Estimated future replacement cost is based on your current policy’s '
  + 'Replacement Cost coverage (aka Dwelling coverage) and the average percent increase of Replacement '
  + 'Cost coverage in your state.';
export const CURR_REPLACEMENT_TOOLTIP = 'Your current replacement cost is based on the Replacement Cost coverage limit '
  + '(aka Dwelling coverage limit) included in your current policy. ';
export const SUG_REPLACEMENT_TOOLTIP = 'Suggested replacement cost range is estimated based on your quoted Replacement '
  + 'Cost coverage (aka Dwelling coverage) and the average percent increase of Replacement Cost coverage in '
  + 'your state. Actual replacement cost can vary, is determined by many factors, and is subject to change.Each '
  + 'consumer is responsible for evaluating their own coverage needs and obtaining a policy that meets those needs.';
