import type { FC } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import useSendMagicLink from '../../api/authentification/use-send-magic-link.ts';
import Illustration from '../../assets/svg/forbidden-sheet.svg?react';
import PageInfoContainer from '../../components/PageInfoContainer/PageInfoContainer.tsx';
import ROUTES from '../../constants/routes.ts';
import Button from '../../shared/components/Button/Button.tsx';

import useTrackPage from '../../hooks/use-track-page.ts';
import useSegment from '../../hooks/use-segment.ts';
import type { TargetPage } from '../../types/target-page.ts';
import { ANALYTICS_SESSION_GID } from '../../constants/browser-storage.ts';
import type { SendMagicLinkPayload } from '../../types/account.type.ts';
import { AnalyticPageKey } from '../../types/analytics.type.ts';
import { illustrationCSS } from './index.style.ts';

const LinkExpiredLayout: FC = () => {
  const [searchParams] = useSearchParams();
  const personGid = searchParams.get('person_gid');
  const targetPage = searchParams.get('target_page');
  const navigate = useNavigate();
  const { mutateAsync: sendMagicLink, isPending: isSendPending } = useSendMagicLink();
  useTrackPage(AnalyticPageKey.LinkExpired);

  const { track } = useSegment();

  const handleResendLink = async (): Promise<void> => {
    const sessionGid = localStorage.getItem(ANALYTICS_SESSION_GID);

    track('Authenticate Link Sent', {
      page: AnalyticPageKey.LinkExpired,
    });

    if (!personGid) {
      // TODO: Throw Sentry error
      return;
    }
    const params: SendMagicLinkPayload = { person_gid: personGid };
    if (targetPage) {
      params.target_page = targetPage as TargetPage;
    }
    if (sessionGid) {
      params.session_gid = sessionGid;
    }
    const res = await sendMagicLink(params);
    navigate(`${ROUTES.checkEmail}?person_gid=${personGid}&email=${res.account.email}&target_page=${targetPage}`);
  };

  return (
    <PageInfoContainer
      heading="Your link has expired"
      subHeading="That link is expired or has already been used, but we can send you a new link."
    >
      <Illustration css={illustrationCSS} />
      <Button fullWidth size="large" isLoading={isSendPending} onClick={handleResendLink}>
        Resend Link
      </Button>
    </PageInfoContainer>
  );
};

export default LinkExpiredLayout;
