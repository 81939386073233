import { type FC, useEffect, useRef, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import useAuthenticate from '../../api/authentification/use-authenticate.ts';
import useAccountQuery from '../../api/account/use-account-query.ts';
import ROUTES from '../../constants/routes.ts';
import FullPageLoader from '../../shared/components/FullPageLoader/FullPageLoader.tsx';
import type { Account } from '../../types/account.type.ts';
import { getTargetPath } from '../../utils/routes.util.ts';
import { useUtmParams } from '../../hooks/use-utm-params.ts';
import { ANALYTICS_SESSION_GID } from '../../constants/browser-storage.ts';
import { useAnalyticsSessionGidCheck } from '../../hooks/use-analytics-session-gid-check.ts';

const Authenticate: FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const personGid = searchParams.get('person_gid');
  const marketingEngagementGid = searchParams.get('engagement_gid');
  const targetPage = searchParams.get('target_page');
  const sessionGidParam = searchParams.get('session_gid');
  const utmParams = useUtmParams();

  const sessionGigLocalStorage = localStorage.getItem(ANALYTICS_SESSION_GID);

  const authSend = useRef(false);
  const [accountResponse, setAccountResponse] = useState<Account | null>(null);
  const [isAuthenticateError, setIsAuthenticateError] = useState(false);
  const { data: accountData, isFetched: isAccountFetched } = useAccountQuery(personGid, false);
  const account = accountData?.account || accountResponse;

  const { mutateAsync: authenticate } = useAuthenticate();

  const { sessionGid } = useAnalyticsSessionGidCheck(sessionGidParam || sessionGigLocalStorage, null, utmParams);

  useEffect(() => {
    const call = async (
      tokenValue: string,
      personGidValue: string,
      sessionGidValue: string,
      marketingEngagementGidValue?: string | null,
    ) => {
      try {
        const response = await authenticate({
          token: tokenValue,
          person_gid: personGidValue,
          engagement_gid: marketingEngagementGidValue,
          session_gid: sessionGidValue,
        });
        setAccountResponse(response.account);
      }
      catch {
        setIsAuthenticateError(true);
      }
    };

    if (token && personGid && !authSend.current && isAccountFetched && !accountData?.account && sessionGid) {
      authSend.current = true;
      call(token, personGid, sessionGid, marketingEngagementGid);
    }
  }, [authenticate, isAccountFetched, personGid, marketingEngagementGid, token, sessionGid, accountData?.account]);

  if (!token || !personGid) {
    return <Navigate to={ROUTES.problem} replace />;
  }

  if (isAuthenticateError) {
    return (
      <Navigate to={`${ROUTES.linkExpired}?person_gid=${personGid}&target_page=${targetPage}`} replace />
    );
  }

  if (!account || !sessionGid) {
    return <FullPageLoader />;
  }

  return account?.verified
    ? (
        <Navigate to={getTargetPath(account.gid, targetPage)} replace />
      )
    : (
        <Navigate to={`${ROUTES.verify.replace(':gid', account.gid)}?target_page=${targetPage}`} replace />
      );
};

export default Authenticate;
