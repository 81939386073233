import { css } from '@emotion/react';

export const rSymbolCSS = css`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  
  span {
    font-weight: 700;
    font-size: 16px;
    position: relative;
    top: -6px;
  }
`;
