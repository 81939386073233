import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { Global } from '@emotion/react';
import { Navigate, useParams } from 'react-router-dom';

import SessionExpiredModal from '../SessionExpiredModal/SessionExpiredModal.tsx';

import { globalCSS } from '../../styles/global.style.ts';
import type { DefaultRouteParams } from '../../types/route-params.type.ts';
import useAccountQuery from '../../api/account/use-account-query.ts';
import analytics from '../../utils/analytics.util.ts';
import { ANALYTICS_SESSION_GID } from '../../constants/browser-storage.ts';
import { useAnalyticsSessionGidCheck } from '../../hooks/use-analytics-session-gid-check.ts';
import FullPageLoader from '../../shared/components/FullPageLoader/FullPageLoader.tsx';
import useQuotesQuery from '../../api/quotes/use-quotes-query.ts';
import Notification from '../../shared/components/Notification/Notification.tsx';
import { QuotingStatus } from '../../types/quote.type.ts';
import isGid from '../../utils/is-gid.util.ts';
import ROUTES from '../../constants/routes.ts';
import SessionRootInner from './SessionRootInner.tsx';

const SessionRoot: FC = () => {
  const { gid } = useParams() as DefaultRouteParams;
  const accountGidParam = isGid(gid) ? gid : null;
  const { data: accountData } = useAccountQuery(accountGidParam, false);
  const { data: quotesData } = useQuotesQuery(
    accountGidParam,
    Boolean(accountData?.account.profile_completed_at),
    false,
  );
  const [showNotification, setShowNotification] = useState(false);

  const accountGid = accountData?.account.gid;
  const personGid = accountData?.account.person_gid;

  const localStorageSessionGid = localStorage.getItem(ANALYTICS_SESSION_GID);

  const { sessionGid } = useAnalyticsSessionGidCheck(localStorageSessionGid);

  useEffect(() => {
    if (quotesData?.quotes_request_status === QuotingStatus.Pending) {
      setShowNotification(true);
    }
  }, [quotesData?.quotes_request_status]);

  useEffect(() => {
    if (accountGid && personGid) {
      analytics.identify(accountGid, {
        email: analytics.generateTrackEmail(personGid),
      });
    }
  }, [accountGid, personGid]);

  const onNotificationClose = () => {
    setShowNotification(false);
  };

  if (!accountGidParam) {
    return <Navigate to={ROUTES.problem} />;
  }

  return (
    <>
      <Notification open={showNotification} message="We are looking for a quote" onClose={onNotificationClose} />
      <Global styles={globalCSS} />
      {
        sessionGid
          ? (
              <>
                <SessionExpiredModal />
                <SessionRootInner />
              </>
            )
          : <FullPageLoader />
      }
    </>
  );
};

export default SessionRoot;
