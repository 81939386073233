import { css } from '@emotion/react';

import { mq } from '../../../styles/media-queries.ts';

export const containerCSS = css`
  padding: 20px 16px;
  ${mq[1]} {
    padding: 20px;
  }
`;

export const titleCSS = css`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  margin-bottom: 20px;
`;

export const cardInnerCSS = css`
  display: flex;
  gap: 12px;
  align-items: center;
  ${mq[1]} {
    gap: 20px;
    flex-direction: column;
    text-align: center;
  }
  ${mq[2]} {
    flex-direction: row;
    text-align: left;
  }
`;

export const iconWrapperCSS = css`
  svg {
    display: block;
  }
`;

export const buttonCSS = css`
  display: block;
  margin: 24px auto 0 auto;
`;

export const factorsCSS = css`
  margin-top: 20px;
`;
