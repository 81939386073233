import type { Policy } from '../../../types/policy.type';
import { CoverageKey, type Quote, type QuoteCoverage } from '../../../types/quote.type';

export function getLimitByCoverageKey(coverages: QuoteCoverage[] | null, coverageKey: string) {
  return coverages?.find(coverage => coverage.key === coverageKey)?.limit_description;
}

export function getLimitValueByCoverageKey(coverages: QuoteCoverage[] | null, coverageKey: string) {
  return coverages?.find(coverage => coverage.key === coverageKey)?.limit_value;
}

export function getDeductibleByCoverageKey(coverages: QuoteCoverage[] | null, coverageKey: string) {
  return coverages?.find(coverage => coverage.key === coverageKey)?.deductible_description;
}

export function getDeductibleValueByCoverageKey(coverages: QuoteCoverage[] | null, coverageKey: string) {
  return coverages?.find(coverage => coverage.key === coverageKey)?.deductible_value;
}

export const additionalHomeCoverages = [
  CoverageKey.Hurricane,
  CoverageKey.WindHail,
  CoverageKey.WaterBackup,
  CoverageKey.Earthquake,
  CoverageKey.Flood,
];

export function getIncludedAdditionalCoverages(coverages: QuoteCoverage[] | null) {
  return coverages?.filter(
    coverage =>
      additionalHomeCoverages.includes(coverage.key)
      && coverage.limit_description !== 'Not included'
      && coverage.limit_description !== 'Not included',
  );
}

export function getExcludedAdditionalCoverages(coverages: QuoteCoverage[] | null) {
  return coverages?.filter(
    coverage => coverage.limit_description === 'Not included' && coverage.limit_description === 'Not included',
  );
}

export function getAdditionalCoverageTitle(key: CoverageKey) {
  switch (key) {
    case CoverageKey.WindHail:
      return 'Wind/Hail';
    case CoverageKey.Hurricane:
      return 'Hurricane';
    case CoverageKey.Flood:
      return 'Flood';
    case CoverageKey.Earthquake:
      return 'Earthquake';
    case CoverageKey.WaterBackup:
      return 'Water Backup';
    default:
      return key;
  }
}

export const CONTENT_COMMENTS = {
  dwellingIncrease:
    'We suggest increasing your dwelling coverage to match current market costs for home construction. This adjustment '
    + 'will ensure you can cover the cost to rebuild your home today if there’s a total loss.',
  dwellingDecrease:
    'We’ve found that you might be able to save on your premium by reducing your dwelling coverage. This '
    + 'adjustment will ensure that you still have the necessary coverage to rebuild your home if there’s a total loss.',
  deductibleIncrease: 'Increasing the deductible amount on your current policy might help lower your premium.',
  deductibleDecrease:
    'Reducing your current deductible can lower your out-of-pocket expenses if you need to file a claim.',
  deductibleDefault:
    'This carrier has a minimum required deductible. A higher deductible could help reduce your premium and '
    + 'save you money.',
  waterBackup:
    'This coverage protects against damage from water backing up through sewers, drains, pipes, or '
    + 'sump pumps, which standard home insurance policies usually don\'t include.',
};

export function getDeductibleComment(quote: Quote, recentPolicy: Policy | undefined) {
  const recentDeductibleValue = recentPolicy
    ? getDeductibleValueByCoverageKey(recentPolicy?.coverages, CoverageKey.Dwelling)
    : null;
  const quoteDeductibleValue = getDeductibleValueByCoverageKey(quote.coverages, CoverageKey.Dwelling);
  if (recentDeductibleValue != null && quoteDeductibleValue != null) {
    if (recentDeductibleValue < quoteDeductibleValue) {
      return CONTENT_COMMENTS.deductibleIncrease;
    }
    if (recentDeductibleValue > quoteDeductibleValue) {
      return CONTENT_COMMENTS.deductibleDecrease;
    }
  }
  return CONTENT_COMMENTS.deductibleDefault;
}

export function getDwellingComment(quote: Quote, recentPolicy: Policy | undefined) {
  const recentDwellingValue = recentPolicy
    ? getLimitValueByCoverageKey(recentPolicy?.coverages, CoverageKey.Dwelling)
    : null;
  const quoteDwellingValue = getLimitValueByCoverageKey(quote.coverages, CoverageKey.Dwelling);
  if (recentDwellingValue != null && quoteDwellingValue != null) {
    if (recentDwellingValue < quoteDwellingValue) {
      return CONTENT_COMMENTS.dwellingIncrease;
    }
    if (recentDwellingValue > quoteDwellingValue) {
      return CONTENT_COMMENTS.dwellingDecrease;
    }
  }
}
