import { useCallback, useEffect, useRef } from 'react';

import analytics from '../../../utils/analytics.util.ts';

function useTrackProposalPage(pageKey: string, proposalGid: string) {
  const page = useCallback(
    (pageName: string, config: Record<string, unknown> = {}): void =>
      analytics.page(pageName, {
        ...config,
        proposal_gid: proposalGid,
      }),
    [proposalGid],
  );

  const pageViewedTracked = useRef(false);

  useEffect(() => {
    if (pageKey && !pageViewedTracked.current) {
      page(analytics.generatePageName(pageKey));
      pageViewedTracked.current = true;
    }
  }, [pageKey, page]);
}

export default useTrackProposalPage;
