import type { HistoryResponse, ReplacementCostHistoryItem } from '../../../types/history.type';
import type { InfoPanelContent } from './ReplacementCostChart.type';

export const infoPanelContent: {
  [key: string]: InfoPanelContent;
} = {
  default: {
    message: 'Consider purchasing a policy with Extended Replacement Cost coverage '
    + 'which can offset rising construction costs due to inflation.',
    type: 'warning',
  },
  suggestedHigher: {
    message: 'Your current policy’s Replacement Cost coverage is below the suggested '
    + 'range. In the case of a total loss of your home, you may not be able to rebuild.',
    type: 'negative',
  },
};

export function getInfoPanelContent(data?: HistoryResponse<ReplacementCostHistoryItem>) {
  if (data) {
    const currentYear = new Date().getFullYear();

    const currentYearData = data.history[currentYear];

    return currentYearData.replacement_cost && currentYearData.replacement_cost_min
      && currentYearData.replacement_cost < currentYearData.replacement_cost_min
      ? infoPanelContent.suggestedHigher
      : infoPanelContent.default;
  }
}
