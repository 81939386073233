import type { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import OfferCard from '../../../components/OfferCard/OfferCard.tsx';
import THEME from '../../../styles/theme.ts';
import Button from '../../../shared/components/Button/Button.tsx';
import { pageButtonCSS } from '../../../styles/layout.style.ts';
import LockIcon from '../../../assets/svg/lock.svg?react';
import type { DefaultRouteParams } from '../../../types/route-params.type.ts';
import useAccountQuery from '../../../api/account/use-account-query.ts';
import formatUtil from '../../../utils/format.util.ts';
import useDpNavigate from '../../../hooks/use-dp-navigate.ts';
import { DpCta } from '../../../types/digital-profile.type.ts';
import ROUTES from '../../../constants/routes.ts';
import useQuotesQuery from '../../../api/quotes/use-quotes-query.ts';
import { QuoteKind, QuotingStatus } from '../../../types/quote.type.ts';
import useSegment from '../../../hooks/use-segment.ts';
import { AnalyticLocation, AnalyticPageKey } from '../../../types/analytics.type.ts';
import type { HomeQuoteInsightCardProps } from './HomeQuoteInsightCard.type.ts';
import {
  carrierNumberCSS,
  containerCSS,
  contentCSS,
  explanationCSS,
  fetchingMessageCSS,
  iconCSS,
} from './HomeQuoteInsightCard.style.ts';

const HomeQuoteInsightCard: FC<HomeQuoteInsightCardProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data: accountData } = useAccountQuery(gid);
  const profileCompleted = !!accountData?.account.profile_completed_at;
  const { data: quotesData } = useQuotesQuery(gid, profileCompleted);

  const { track } = useSegment();

  const noDataForQuotes = !profileCompleted || !quotesData?.quotes_request_status;

  const navigateToDp = useDpNavigate();
  const navigate = useNavigate();

  const address = accountData?.account.address;

  const onActionClick = () => {
    if (profileCompleted) {
      track('Insights Navigation Clicked', {
        target_page: AnalyticPageKey.CarrierOptions,
      });
      navigate(ROUTES.carrierOptions.replace(':gid', gid));
    }
    else {
      track('Unlock Portal Data Requested', {
        page: AnalyticPageKey.Insights,
        location: AnalyticLocation.HomeQuoteInsightCard,
      });
      navigateToDp(DpCta.Quotes, AnalyticPageKey.CarrierOptions);
    }
  };

  const getContent = () => {
    switch (true) {
      case noDataForQuotes:
        return (
          <>
            <LockIcon css={iconCSS} />
            <div css={explanationCSS}>We need more information to run quotes</div>
          </>
        );
      case quotesData?.quotes_request_status !== QuotingStatus.Finished:
        return (
          <div css={fetchingMessageCSS}>fetching...</div>
        );
      case quotesData?.quotes_request_status === QuotingStatus.Finished && !!quotesData?.quotes:
        return (
          <div css={carrierNumberCSS}>{quotesData.quotes.filter(q => q.kind !== QuoteKind.NonIntegrated).length}</div>
        );
    }
  };

  return (
    <div css={customCSS}>
      <OfferCard
        customCSS={containerCSS}
        title="Did you know?"
        message="Carriers consider many factors when deciding whether to write a policy for
        your home. See the factors that are impacting your home quote options."
        backgroundColor={THEME.color.violet95}
        messageColor={THEME.color.violet}
      >
        <div css={contentCSS}>
          <div>
            Carriers for {address ? formatUtil.address(address) : ''}
          </div>
          {getContent()}
        </div>
      </OfferCard>
      <Button
        customCSS={pageButtonCSS}
        variant="secondary"
        onClick={onActionClick}
      >
        {noDataForQuotes ? 'Unlock your quotes' : 'See your quote options' }
      </Button>
    </div>
  );
};

export default HomeQuoteInsightCard;
