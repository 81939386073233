import { type FC, Fragment } from 'react';

import usePersonAssetsQuery from '../../../api/proposal/use-person-assets-query';
import usePersonQuery from '../../../api/proposal/use-person-query';
import useRelatedPeopleQuery from '../../../api/proposal/use-related-people-query';
import ProposalCard from '../../../components/ProposalCard/ProposalCard';
import { CoverageKey } from '../../../types/quote.type';
import { moneyFormatter } from '../../../utils/formatters';
import {
  autoDescriptionCSS,
  autoTitleCSS,
  boldTextCSS,
  carrierLineCSS,
  containerCSS,
  coverageLineCSS,
  dividerLineCSS,
  headerCSS,
  headerLineCSS,
} from './ProposalAutoQuoteInfo.style';
import type { ProposalAutoQuoteInfoProps } from './ProposalAutoQuoteInfo.type';
import {
  buildVehicleTitle,
  generateAutoDescription,
  getAssetDeductibleByCoverageKey,
  getAssetLimitByCoverageKey,
  getLimitByCoverageKey,
} from './ProposalAutoQuoteInfo.util';

const ProposalAutoQuoteInfo: FC<ProposalAutoQuoteInfoProps> = ({ quote, personGid }) => {
  const { data: assets } = usePersonAssetsQuery({ personGid, assetGids: quote.assets_gids });
  const { data: relatedPeople } = useRelatedPeopleQuery({ personGid });
  const { data: person } = usePersonQuery({ personGid });

  const propertyDamage = getLimitByCoverageKey(quote.coverages, CoverageKey.PropertyDamage);
  const biPerPerson = getLimitByCoverageKey(quote.coverages, CoverageKey.BiPerPerson);
  const biPerAccident = getLimitByCoverageKey(quote.coverages, CoverageKey.BiPerAccident);
  const uninsuredMpd = getLimitByCoverageKey(quote.coverages, CoverageKey.UninsuredMpd);
  const coinsuredNames = relatedPeople
    ?.filter(person => quote.coinsureds_gids?.includes(person.gid))
    .map(person => person.first_name);

  const insuredNames = [person?.first_name, ...(coinsuredNames || [])].filter(Boolean);
  return (
    <ProposalCard isTextCard>
      <div css={containerCSS}>
        <p css={headerCSS}>Auto insurance details</p>
        <p css={autoDescriptionCSS}>{generateAutoDescription(assets?.vehicles)}</p>
        <div css={carrierLineCSS}>
          <p css={boldTextCSS}>Carrier</p>
          <img src={quote.carrier.logo_url} alt={quote.carrier.key} width="120" />
        </div>
        <div css={headerLineCSS}>
          <p css={boldTextCSS}>Matic recommended coverages</p>
          <p css={boldTextCSS}>Limit</p>
        </div>
        {propertyDamage && (
          <div css={coverageLineCSS}>
            <p>Property damage</p>
            <p>{propertyDamage}</p>
          </div>
        )}
        {biPerPerson && (
          <div css={coverageLineCSS}>
            <p>Bodily injury liability per person</p>
            <p>{biPerPerson}</p>
          </div>
        )}
        {biPerAccident && (
          <div css={coverageLineCSS}>
            <p>Bodily injury liability per accident</p>
            <p>{biPerAccident}</p>
          </div>
        )}
        {uninsuredMpd && (
          <div css={coverageLineCSS}>
            <p>Uninsured motorist property damage</p>
            <p>{uninsuredMpd}</p>
          </div>
        )}
        {assets?.vehicles
        && assets.vehicles.length > 0
        && assets.vehicles.map((vehicle) => {
          const collisionDeductible = getAssetDeductibleByCoverageKey({
            assetGid: vehicle?.gid,
            coverageKey: CoverageKey.Collision,
            coverages: quote.coverages,
          });
          const comprehensiveDeductible = getAssetDeductibleByCoverageKey({
            assetGid: vehicle?.gid,
            coverageKey: CoverageKey.Comprehensive,
            coverages: quote.coverages,
          });
          const towing = getAssetLimitByCoverageKey({
            assetGid: vehicle?.gid,
            coverageKey: CoverageKey.Towing,
            coverages: quote.coverages,
          });
          const fullGlass = getAssetLimitByCoverageKey({
            assetGid: vehicle?.gid,
            coverageKey: CoverageKey.FullGlass,
            coverages: quote.coverages,
          });

          return (
            <Fragment key={vehicle?.gid}>
              <p css={autoTitleCSS}>{buildVehicleTitle(vehicle)}</p>
              {collisionDeductible && (
                <div css={coverageLineCSS}>
                  <p>Collision deductible</p>
                  <p>{collisionDeductible}</p>
                </div>
              )}
              {comprehensiveDeductible && (
                <div css={coverageLineCSS}>
                  <p>Comprehensive deductible</p>
                  <p>{comprehensiveDeductible}</p>
                </div>
              )}
              {towing && (
                <div css={coverageLineCSS}>
                  <p>Roadside assistance coverage</p>
                  <p>{towing}</p>
                </div>
              )}
              {fullGlass && (
                <div css={coverageLineCSS}>
                  <p>Full glass</p>
                  <p>{fullGlass}</p>
                </div>
              )}
            </Fragment>
          );
        })}
        {insuredNames && insuredNames.length > 0 && (
          <div css={coverageLineCSS}>
            <p css={boldTextCSS}>Insured drivers</p>
            <p>{insuredNames?.join(', ')}</p>
          </div>
        )}
        <div css={dividerLineCSS} />
        <div css={coverageLineCSS}>
          <p css={headerCSS}>6 months premium</p>
          <p css={headerCSS}>{moneyFormatter(quote.premium)}</p>
        </div>
      </div>
    </ProposalCard>
  );
};

export default ProposalAutoQuoteInfo;
