import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import InfoRequestTooltip from '../../../components/InfoRequestTooltip/InfoRequestTooltip.tsx';

import ReplacementCostGraph from '../QuotesOverTimeGraph/QuotesOverTimeGraph.tsx';
import { CHART_DATA_SET } from '../QuotesOverTimeGraph/QuotesOverTimeGraph.utils.ts';
import { HistoryDataStatuses } from '../../../types/history.type.ts';
import type { DefaultRouteParams } from '../../../types/route-params.type.ts';
import useAccountQuery from '../../../api/account/use-account-query.ts';
import formatUtil from '../../../utils/format.util.ts';
import {
  addressCSS,
  chartCSS,
  hintCSS,
  notificationCSS,
  titleCSS,
} from '../../../styles/chart.style.ts';
import { getChartState, transformQuotesOverTimeHistoryData } from '../../../utils/chart.util.ts';
import useQuotesOverTimeQuery from '../../../api/history/use-quotes-over-time-history-query.ts';
import SkeletonLoader from '../../../shared/components/SkeletonLoader/SkeletonLoader.tsx';
import useQuotesQuery from '../../../api/quotes/use-quotes-query.ts';
import { QuotingStatus } from '../../../types/quote.type.ts';
import { AnalyticPageKey } from '../../../types/analytics.type.ts';
import type { QuotesOverTimeHistoryProps } from './QuotesOverTimeHistory.type.ts';

const QuotesOverTimeHistory: FC<QuotesOverTimeHistoryProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data: accountData, isLoading: accountQueryIsLoading } = useAccountQuery(gid);
  const { data: quotesData } = useQuotesQuery(gid, !!accountData?.account.profile_completed_at);
  const {
    data: historyData,
  } = useQuotesOverTimeQuery(gid, quotesData?.quotes_request_status === QuotingStatus.Finished);

  const address = accountData?.account.address ? formatUtil.address(accountData.account.address) : '';

  const chartState = getChartState(
    quotesData?.quotes_request_status,
    accountData,
    quotesData,
    historyData,
  );

  if (accountQueryIsLoading) {
    return (
      <>
        <SkeletonLoader width={75} />
        <SkeletonLoader width={65} marginTop={9} marginBottom={24} />
        <SkeletonLoader width={100} absoluteHeight={243} />
      </>
    );
  }

  const getContent = () => {
    switch (chartState) {
      case HistoryDataStatuses.Ready:
        return (
          <div css={chartCSS(false)}>
            <ReplacementCostGraph data={transformQuotesOverTimeHistoryData(historyData!.history)} hidePreview={false} />
            <div css={hintCSS}>Hint: Hover over graph to see data per year.</div>
          </div>
        );
      case HistoryDataStatuses.Pending:
        return (
          <div css={chartCSS()}>
            <ReplacementCostGraph data={transformQuotesOverTimeHistoryData(CHART_DATA_SET.history)} />
            <div css={notificationCSS}>
              <h3>Almost There!</h3>
              <p>We are still fetching quotes for your home. We will update you when the process is complete.</p>
            </div>
          </div>
        );
      case HistoryDataStatuses.Unknown:
      default:
        return (
          <div css={chartCSS()}>
            <ReplacementCostGraph data={transformQuotesOverTimeHistoryData(CHART_DATA_SET.history)} />
            <InfoRequestTooltip page={AnalyticPageKey.CarrierOptions} />
          </div>
        );
    }
  };

  return (
    <div css={customCSS}>
      <div css={titleCSS}>Quotes over time</div>
      <p css={addressCSS}>🏠 {address}</p>
      {getContent()}
    </div>
  );
};

export default QuotesOverTimeHistory;
