import type { HistoryResponse } from '../../../types/history.type';
import type { InfoPanelContent } from '../../replacement-cost/ReplacementCostHistory/ReplacementCostChart.type';

export const infoPanelContent: {
  [key: string]: InfoPanelContent;
} = {
  default: {
    message: 'Make sure you have the protection you need when it matters most. Compare the '
    + 'coverages in your current policy with the coverages included with Your Personalized Quote.',
    type: 'warning',
  },
  currentHigher: {
    message: 'Matic found a quote that is lower than your current premium. {{schedule_a_call}} '
    + 'to start saving on your home insurance.',
    type: 'positive',
  },
  predictedHigher: {
    message: 'Your premium is projected to increase at your next renewal. Now is a good '
    + 'time to explore your options with Matic.',
    type: 'positive',
  },
};

export function getInfoPanelContent(data?: HistoryResponse) {
  if (!data)
    return;

  const currentYear = new Date().getFullYear();
  const currentYearData = data.history[currentYear];
  const nextYearData = data.history[currentYear + 1];

  const { policy_premium: currentPolicyPremium, quote_premium: currentQuotePremium } = currentYearData;
  const { policy_premium: nextPolicyPremium } = nextYearData;

  if (currentPolicyPremium && currentQuotePremium && currentPolicyPremium > currentQuotePremium) {
    return infoPanelContent.currentHigher;
  }

  if (nextPolicyPremium && currentQuotePremium && nextPolicyPremium > currentQuotePremium) {
    return infoPanelContent.predictedHigher;
  }

  return infoPanelContent.default;
}
