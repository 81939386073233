import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

export function containerInnerCSS(showBackButton: boolean): SerializedStyles {
  return css`
    margin: 0 auto 0;
    padding-top: ${showBackButton ? 27 : 48}px;
    padding-bottom: 130px;
  `;
}
