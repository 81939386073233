import type { FC } from 'react';
import useSettingsQuery from '../../../api/settings/use-settings-query.ts';
import AutoImage from '../../../assets/svg/auto-on-road.svg?react';
import OfferCard from '../../../components/OfferCard/OfferCard.tsx';
import Button from '../../../shared/components/Button/Button.tsx';
import { pageButtonCSS } from '../../../styles/layout.style.ts';
import THEME from '../../../styles/theme.ts';
import type { AutoInsuranceInsightCardProps } from './AutoInsuranceInsightCard.type.ts';

import { containerCSS, imageCSS } from './AutoInsuranceInsightCard.style.ts';

const AutoInsuranceInsightCard: FC<AutoInsuranceInsightCardProps> = ({ customCSS }) => {
  const { data: settingsData } = useSettingsQuery();

  const onActionClick = () => {
    if (settingsData?.matic_auto_flow_url) {
      window.location.href = settingsData.matic_auto_flow_url;
    }
  };

  return (
    <div css={customCSS}>
      <OfferCard
        customCSS={containerCSS}
        title="Did you know?"
        message="Reshopping your auto insurance is a great way to see if you can save by switching auto carriers."
        backgroundColor={THEME.color.positiveGreen94}
        messageColor={THEME.color.positiveGreen27}
      >
        <AutoImage css={imageCSS} />
      </OfferCard>
      <Button customCSS={pageButtonCSS} variant="secondary" onClick={onActionClick}>
        Get your quotes
      </Button>
    </div>
  );
};

export default AutoInsuranceInsightCard;
